<template>
  <div>
    <div class="facilityBox">
      <el-alert
        style="margin-bottom: 10px"
        type="warning"
        :closable="false"
        title="温馨提示：已认证司机、车辆根据司机身份证号与车牌号来判定，同人同车多次提交认证，视为认证1人、1车。"
      >
      </el-alert>
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input
            class="fromInp"
            v-model="manageForm.LegalPersonlPhone"
            placeholder="输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注：" prop="VerifyRemark">
          <el-input
            class="fromInp"
            v-model="manageForm.VerifyRemark"
            placeholder="输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="SubmitDatetime"
          label="提交时间"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Status"
          label="货主状态"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.Status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverCnt"
          label="未认证司机数"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff2323">{{ scope.row.DriverCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverCntCertified"
          label="已认证司机数"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="VehicleCnt"
          label="未认证车辆数"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff2323">{{ scope.row.VehicleCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VehicleCntCertified"
          label="已认证车辆数"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="VerifyRemark"
          label="备注"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="500">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="openDialogVisible(scope.row)"
 
            >
              修改备注
            </el-button>

            <el-button
              type="primary"
              size="small"
              @click="approveDriver(scope.row)"
              icon="el-icon-user-solid"
            >
              认证司机
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="approveCar(scope.row)"
              icon="el-icon-truck"
            >
              认证车辆
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row, pagination.page)"
              icon="el-icon-document"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="form.dialogVisible"
      width="30%"
      :before-close="
        () => {
          form.dialogVisible = false;
        }
      "
    >
      <div class="demo-input-suffix">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="备注">
            <el-input
              type="text"
              show-word-limit
              v-model="form.dialogVisibleVerifyRemark"
              maxlength="32"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="form.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateVerifyRemarkAsync">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetEnterprise, UpdateVerifyRemark } from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      form: {
        dialogVisible: false,
        dialogVisibleVerifyRemark: "",
        dialogVisibleVerifyRemarkUserId: 0,
      },

      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 列表数据
      tableData: [],
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        VerifyRemark: "",
        TaskDatetime: ["", ""],
      },
      // 表格loading
      loading: true,
    };
  },
  activated() {
    // 获取列表数据
    this.GetEnterprise();
  },
  created() {
    // 获取列表数据
    // this.GetEnterprise();
  },
  methods: {
    searchChange() {
      this.search();
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        VerifyRemark: "",
        TaskDatetime: ["", ""],
      };
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        VerifyRemark: this.manageForm.VerifyRemark,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    search() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        VerifyRemark: this.manageForm.VerifyRemark,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        VerifyRemark: this.manageForm.VerifyRemark,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: e,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询列表（货主，服务商）列表
    GetEnterprise() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        VerifyRemark: this.manageForm.VerifyRemark,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 跳转详情
    goFacility(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/auditOwner/certifiedInfo",
        query: { UserID: item.UserID, pageIndex },
      });
    },
    // 认证司机
    approveDriver(item) {
      this.$router.push({
        path: "/auditInfo/certifiedDriver/index",
        query: { UserID: item.UserID },
      });
    },
    openDialogVisible(item) {
      this.form.dialogVisibleVerifyRemark = item.VerifyRemark;
      this.form.dialogVisibleVerifyRemarkUserId = item.UserID;
      this.form.dialogVisible = true;
    },
    UpdateVerifyRemarkAsync() {
      const loading = this.$loading({
        lock: true,
        text: "修改中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      UpdateVerifyRemark({
        UserID: this.form.dialogVisibleVerifyRemarkUserId,
        VerifyRemark: this.form.dialogVisibleVerifyRemark,
      })
        .then((res) => {
          this.form.dialogVisibleVerifyRemarkUserId = 0;
          this.form.dialogVisibleVerifyRemark = "";
          this.form.dialogVisible = false;
          this.search();
        })
        .finally(() => {
          loading.close();
        });
    },
    // 认证车辆
    approveCar(item) {
      this.$router.push({
        path: "/auditInfo/certifiedCar/index",
        query: { UserID: item.UserID },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
